export const MAPURL =
  'https://api.maptiler.com/maps/hybrid/256/{z}/{x}/{y}.jpg?key=NOHd1j0dpdUprP5PNjS3'
export const ATTRIBUTIONS =
  '<a href="https://juejin.cn/user/588993965598407" target="_blank">&copy; 前端小菜鸟吖</a>'
export const SHENZHEN = [114.064839, 22.548857] // 深圳坐标
export const NANCHANG = [115.892151, 28.676493] // 南昌市
export const FUZHOU = [116.358351, 27.98385] // 抚州市

// 作者信息
export const AUTHOR_INFO = {
  NAME: '前端小菜鸟吖',
  SEX: '男',
  AGE: new Date().getFullYear() - 1998,
  JUEJIN: 'https://juejin.cn/user/588993965598407',
  QQ: 2582395486,
  WX: 15979580504,
  AVATAR: 'https://q2.qlogo.cn/headimg_dl?dst_uin=2582395486&spec=100',
  LOCATION: '深圳',
  SCHOOL: '江西工程职业技术学院',
  GITHB: 'https://github.com/raomaiping',
  DOCUMENT: 'http://map.raomaiping.host/',
  DESCRIPTION: `一个练习时长有${
    new Date().getFullYear() - 2019
  }年多的前端小菜鸟`,
  JOB_DESCRIPTION: '目前已经离职，期望寻求一份前端工程师的工作。',
  RESUME: {
    URL: 'https://raomaiping.host/%E9%A5%B6%E8%BF%88%E5%B9%B3-%E5%89%8D%E7%AB%AF%E5%B7%A5%E7%A8%8B%E5%B8%88-3%E5%B9%B4.pdf',
    FILENAME: '饶迈平-前端工程师-3年.pdf',
  },
}
export const IS_JOB_SEARCH = false // 是否开始找工作
